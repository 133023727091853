<template>
  <default-layout>
    <section>
      <div class="container">
        <page-loading :show="loading" />
        <div class="wrapper--title">
          <h4 class="modal-title">{{ $t('booking.digitalAgreement') }}</h4>
          <hr />
        </div>
        <div class="wrapper--main">
          <div style="overflow-x: scroll" v-html="digitalSignTemplate"></div>
          <div class="wrapper--footer">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkboxagreement"
                name="agreement"
                v-model="agreement"
              />
              <label class="custom-control-label" for="checkboxagreement">
                {{ $t('booking.messageAgreement') }}</label
              >
            </div>
            <div class="d-flex mt-2">
              <button class="btn btn-primary mr-2" @click="sign" :disabled="!agreement">
                {{ $t('booking.sign') }}
              </button>
              <button
                @click="openCancelBookModal"
                class="btn btn-danger color-white mx-1"
                type="button"
                :disabled="agreement"
              >
                {{ $t('booking.notAgree') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <reject-booking-modal @actionDone="backToDetail" />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading');

const RejectBookingModal = () =>
  import('@/components/application-action/modals/booking/reject-modal');
export default {
  name: 'booking-sign',
  components: {
    DefaultLayout,
    PageLoading,
    RejectBookingModal,
  },
  async fetch({ store, params, router, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    const uuid = params.uuid;
    try {
      const { data } = await store.dispatch('v2/booking/checkDigitalSign', uuid);
      if (!data) router.replace('/');
    } catch (_) {
      router.replace('/');
    }
    await store.dispatch('v2/booking/getDigitalSignTemplate', uuid);
  },
  data() {
    return {
      agreement: false,
    };
  },
  computed: {
    ...mapState({
      digitalSignTemplate: state => state.v2.booking.digitalSignTemplate,
      loading: state => state.v2.booking.signLoading,
    }),
  },
  methods: {
    openCancelBookModal() {
      this.$modal.show('booking-reject-modal', {
        uuid: this.$route.params.uuid,
        buttonName: 'Batalkan Booking',
      });
    },
    async sign() {
      let data = await this.$store.dispatch('v2/booking/digitalSign', this.$route.params.uuid);
      if (data.type === 'success') {
        this.$store.commit('v2/booking/set_success_submit', true);
        await this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.sign.message'),
          'success',
        );
        this.backToDetail();
      }
    },
    backToDetail() {
      this.$router.replace(`/mybooking/detail?type=BOOKING&uuid=${this.$route.params.uuid}`);
    },
  },
};
</script>

<style scoped></style>
